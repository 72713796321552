export const PROVIDER_NAMES = {
  INDIA_POST: "India Post",
  ECOM: "Ecom Express",
  FEDEX: "Fedex",
  DELHIVERY: "Delhivery",
  DELHIVERY_INTERNATIONAL: "Delhivery International",
  CRITICALOG: "CriticaLog",
  SHREEMARUTI: "Shree Maruti",
  SHADOWFAX: "ShadowFax",
  XPRESSBEES: "XpressBees",
  AMAZON: "Amazon",
  QWQER: "Qwqer",
  DUNZO: "Dunzo",
  SELF_DROP: "Self Drop!",
  GATI: "Gati",
  DTDC: "DTDC",
  BLUEDART: "Blue Dart",
  SMARTR: "Smartr",
  DTDC_INTERNATIONAL: "Dtdc International",
  MOVIN: "Movin",
  EKART: "Ekart",
};
