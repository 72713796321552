import { useEffect, useState } from "react";
import Tracking from "./components/Tracking";

const apiUrl = process.env.REACT_APP_API_URL;

function App() {
  const [trackingData, setTrackingData] = useState({});
  const [awb, setAwb] = useState(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const awbValue = searchParams.get("awb");
    setAwb(awbValue);
  }, []);

  const fetchData = async () => {
    const data = await fetch(`${apiUrl}/tracking/${awb}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const result = await data.json();
    setTrackingData(result?.data || {});
  };

  useEffect(() => {
    if (awb) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [awb]);

  return <Tracking trackingData={trackingData} />;
}

export default App;
