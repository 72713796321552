import React, { useEffect, useState } from "react";
import styles from "../styles/tracking.module.scss";
import { formatDateAndTime, timeAgo } from "./function";
import { GetProviderLogo } from "../utils/getProviderLogo";

const cloudinaryURL = process.env.REACT_APP_CLOUDINARY_URL;

const Tracking = ({ trackingData }) => {
  const { orderDetails, trackingDetails } = trackingData;
  const [openDetails, setOpenDetails] = useState([]);

  useEffect(() => {
    if (trackingDetails && trackingDetails.length > 0)
      setOpenDetails(
        new Array(trackingDetails[0]?.statusLog?.length).fill(true)
      );
  }, [trackingDetails]);

  const toggleDetail = (index) => {
    setOpenDetails((prevOpenDetails) => {
      const updatedOpenDetails = [...prevOpenDetails];
      updatedOpenDetails[index] = !updatedOpenDetails[index];
      return updatedOpenDetails;
    });
  };

  const essentialStatuses = [
    { code: "MANIFESTED", label: "Order Placed" },
    { code: "PICKUP_DONE", label: "Order Pickup" },
    { code: "IN_TRANSIT", label: "In Transit" },
    { code: "DELIVERED", label: "Delivered" },
  ];

  if (!trackingDetails || trackingDetails.length === 0) {
    return (
      <div className={styles["tracking"]}>
        <h3 className={styles["__heading"]}>No tracking details available</h3>
      </div>
    );
  }

  const { statusLog } = trackingDetails[0];

  const latestStatuses = essentialStatuses.map((status) => ({
    ...status,
    event: statusLog?.find((log) => log.mainStatus === status.code),
  }));

  console.log(orderDetails?.delivery_vendor);

  return (
    <div className={styles["tracking"]}>
      <h3 className={styles["__heading"]}>Tracking detail</h3>

      <div className={styles["__body"]}>
        <div className={styles["tracking-details"]}>
          <div className={styles["track"]}>
            <div className={styles["details"]}>
              <h5 className={styles["label"]}>AWB/Order Id</h5>
              <h4 className={styles["value"]}>{orderDetails?.order_id}</h4>
            </div>
            <div className={styles["details"]}>
              <h5 className={styles["label"]}>Origin</h5>
              <h4 className={styles["value"]}>
                {orderDetails?.pickup_address_city}
              </h4>
            </div>
            <div className={styles["details"]}>
              <h5 className={styles["label"]}>Origin Pincode</h5>
              <h4 className={styles["value"]}>
                {orderDetails?.pickup_address_pincode}
              </h4>
            </div>
          </div>

          <div className={styles["pick-drop"]}>
            <div className={styles["pick"]}>
              <h5 className={styles["label"]}>Pickup</h5>
              <div className={styles["pickup-partner"]}>
                <img
                  src={GetProviderLogo(orderDetails?.pickup_vendor || "")}
                  alt="pickupVendorLogo"
                  width={80}
                  height={100}
                  style={{
                    objectFit: "contain",
                    display: "block",
                    margin: "0 auto",
                  }}
                />
              </div>
            </div>
            <div>
              <span className={styles["label"]}></span>
              <img
                src={`${cloudinaryURL}/Vector_Images/transferIcon.svg`}
                alt="Transfer icon"
              />
            </div>
            <div className={styles["drop"]}>
              <h5 className={styles["label"]}>Drop</h5>
              <div className={styles["drop-partner"]}>
                <img
                  src={GetProviderLogo(orderDetails?.delivery_vendor || "")}
                  alt="deliveryVendorLogo"
                  width={80}
                  height={100}
                  style={{
                    objectFit: "contain",
                    display: "block",
                    margin: "0 auto",
                  }}
                />
              </div>
            </div>
          </div>

          <div className={styles["track"]} style={{ textAlign: "right" }}>
            <div className={styles["details"]}>
              <h5 className={styles["label"]}>Last updated</h5>
              <h4 className={styles["value"]}>
                {timeAgo(orderDetails?.modified_date)}
              </h4>
            </div>
            <div className={styles["details"]}>
              <h5 className={styles["label"]}>Destination</h5>
              <h4 className={styles["value"]}>
                {orderDetails?.delivery_address_city}
              </h4>
            </div>
            <div className={styles["details"]}>
              <h5 className={styles["label"]}>Destination Pincode</h5>
              <h4 className={styles["value"]}>
                {orderDetails?.delivery_address_pincode}
              </h4>
            </div>
          </div>

          {/* <div className={styles["expected-delivery"]}>
            <h5 className={styles["label"]}>Expected delivery</h5>
            <h2 className={styles["day"]}>
              {orderDetails?.expectedDeliveryDate}
            </h2>
          </div> */}
        </div>

        <div className={styles["status-bar"]}>
          {latestStatuses.map((status, index) => (
            <React.Fragment key={index}>
              <div
                key={index}
                className={status.event ? styles["active"] : styles["pending"]}
              >
                {status.event ? (
                  <img
                    src={`${cloudinaryURL}/Vector_Images/greenCircle.svg`}
                    alt={`${status.label} icon`}
                  />
                ) : (
                  <div className={styles["dot"]} />
                )}

                <h5 className={styles["status-label"]}>{status.label}</h5>
                {status.event && (
                  <h5 className={styles["status-date"]}>
                    {
                      formatDateAndTime(status.event.subStatuses[0].dateTime)
                        .formattedDate
                    }
                  </h5>
                )}
              </div>
              <div className={styles["arrow"]}>
                <img
                  src={`${cloudinaryURL}/Vector_Images/greyLongArrow.svg`}
                  alt="grey arrow"
                />
              </div>
            </React.Fragment>
          ))}
        </div>

        <div className={styles["tracking-view"]}>
          <h5 className={styles["title"]}>Tracking view</h5>

          {statusLog && statusLog.length > 0 ? (
            <>
              {statusLog.map((log, index) => (
                <div key={index} className={styles["details"]}>
                  <div
                    onClick={() => toggleDetail(index)}
                    className={styles["track-detail"]}
                  >
                    <div className={styles["track-information"]}>
                      <h4 className={styles["date"]}>Status: </h4>
                      <h5 className={styles["action"]}>{log.mainStatus}</h5>
                    </div>
                    <img
                      src={`${cloudinaryURL}/Vector_Images/${
                        openDetails[index] ? "upVector.svg" : "downVector.svg"
                      }`}
                      alt="vector"
                    />
                  </div>

                  {log.subStatuses &&
                    log.subStatuses.length > 0 &&
                    openDetails[index] && (
                      <>
                        {log.subStatuses.map((subStatus, subIndex) => (
                          <div
                            key={subIndex}
                            className={styles["track-update"]}
                          >
                            <div className={styles["update-msg"]}>
                              <h5 className={styles["time"]}>
                                {
                                  formatDateAndTime(subStatus.dateTime)
                                    .formattedTime
                                }
                              </h5>
                              <h5 className={styles["location"]}>
                                {subStatus.location}
                              </h5>
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                </div>
              ))}
            </>
          ) : (
            <p>No status logs available.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Tracking;
